<template>
    <transition name="fade">
        <component :is="layout" style="height: 100%" />
    </transition>
    <ModalsContainer></ModalsContainer>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { ModalsContainer, useModal } from 'vue-final-modal'
import { useMeta, usePageTitle } from "./composables/use-meta";
import useStore from '@/stores'
import { useUserStore } from '@/stores/user'
import { getUserData, getContentUsers, getTimelineActions, getBillingTodoActions, updateFcmToken, logUserAction, shouldAlertUnfinishedContent } from "@/services/api/http";
import Loading from "./layouts/Loading.vue"
import DefaultLayout from "./layouts/Default.vue";
import AuthLayout from "./layouts/Auth.vue";
import { isNotifiicationSupported } from '@/composables/utility';
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
import DialogModal from '@/components/DialogModal.vue'
import { useRouter } from 'vue-router';

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
usePageTitle("Home");

const store = useStore()
const userStore = useUserStore()
const router = useRouter()

const alertModal = useModal({
    component: DialogModal,
    attrs: {
        onClose() {
            alertModal.close()
        },
        onCancel() {
            logUserAction("May be later")
            alertModal.close()
        },
        onConfirm() {
            logUserAction("Go to unfinished content")
            alertModal.close()
            router.push({ name: 'content.unfinished'})
        },
        modalAttrs: {
            clickToClose: false,
            escToClose: false,
        },
        hideClose: true,
        title: 'กรุณาไปใส่ข้อมูล Content ใ้ห้ครบ',
        cancelTitle: 'ไว้ทีหลัง',
        submitTitle: 'ไปทันที',
        submitBtnClass: 'btn-primary'
    },
    slots: {
        default: 'ได้โปรดดดดดดด'
    }
})

// await new Promise(resolve => setTimeout(resolve, 3000));

if(store.isAuthenticated) {
    Promise.all([
        getContentUsers().then((users) => {
            store.content_users = users
        }),
        getTimelineActions().then((actions) => {
            store.timeline_actions = actions
        }),
        getBillingTodoActions().then((actions) => {
            store.billing_actions = actions
        }),
        getUserData().then((data) => {
            store.isAuthenticated = true
            userStore.setUser(data.user)
            userStore.setRoles(data.roles)
        }).catch(e => {

        }),
        shouldAlertUnfinishedContent().then( (shouldAlert: boolean) => {
            if(shouldAlert) {
                alertModal.open()
            }
        })
    ]).finally(() => {
        store.isInitialized = true
    })
    isSupported().then( isMessagingSupported => {
        if (isMessagingSupported && isNotifiicationSupported && Notification.permission == 'granted') {
            const messaging = getMessaging();
            getToken(messaging, { vapidKey: 'BApWnBzaDvqeO2_NmrYdlpt8CDrPlIGgRSRhNbUSGTf5fIKpOaLKsabUl4bkeclzC0hIYDD9gsdV8QhSZrHuImY' }).then((currentToken) => {
                if (currentToken) {
                    // Send the token to your server and update the UI if necessary
                    // ...
                    console.log(currentToken)
                    updateFcmToken(currentToken)
                } else {
                    // Show permission request UI
                    alert('No registration token');
                    console.log('No registration token available. Request permission to generate one.');
                    // ...
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                // ...
            });
        }
    })
} else {
    setTimeout(() => {
        store.isInitialized = true
    }, 500)
}


// store.isInitialized = true
const layouts: Record<string, any> = {
    DefaultLayout: DefaultLayout,
    LoadingLayout: Loading,
    AuthLayout: AuthLayout
};

const layout = computed(() => {
    let key = 'DefaultLayout'
    if(!store.isInitialized) {
        key = 'LoadingLayout'
    }
    else if(store.currentRouterLayout == 'auth') {
        key = 'AuthLayout'
    }
    return layouts[key]
})

</script>

<style>
.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.vue:hover {
  filter: drop-shadow(0 0 2em #42b883aa);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
