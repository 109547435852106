<template>
    <div>
        <!--  BEGIN NAVBAR  -->
        <Header></Header>
        <!--  END NAVBAR  -->


        <!--  BEGIN MAIN CONTAINER  -->
        <div class="main-container" id="container" :class="[!store.is_show_sidebar ? 'sidebar-closed sbar-open' : '', store.menu_style === 'collapsible-vertical' ? 'collapsible-vertical-mobile' : '']">
            <!--  BEGIN OVERLAY  -->
            <div class="overlay" :class="{ show: !store.is_show_sidebar }" @click="store.toggleSideBar()"></div>
            <div class="search-overlay"></div>
            <!-- END OVERLAY -->

            <!--  BEGIN SIDEBAR  -->
            <Sidebar></Sidebar>
            <!--  END SIDEBAR  -->

            <!--  BEGIN CONTENT AREA  -->
            <div id="content" class="main-content">

                <router-view></router-view>
                <!-- BEGIN FOOTER -->

                <!-- END FOOTER -->
            </div>
            <!--  END CONTENT AREA  -->
        </div>
    </div>
</template>

<script setup lang="ts">
    import Header from "../components/layout/header.vue";
    import Sidebar from "../components/layout/sidebar.vue";
    import useStore from '@/stores'

    const store = useStore();
</script>
