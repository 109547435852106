import { useHead } from '@vueuse/head';
import { unref, computed } from 'vue';
import { Head} from '@unhead/schema';

let siteTitle = 'Whaleplan';
let separator = '|';

export const usePageTitle = (pageTitle: string) =>
    useHead(
        computed(() => ({
            title: `${unref(pageTitle)} ${separator} ${siteTitle}`,
        }))
    );

export const useMeta = (data: Head) => {
    return useHead({ ...data, title: `${data.title}` });
};
