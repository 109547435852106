import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import useStore from '@/stores'
import {useUserStore} from '@/stores/user'
import PageContentType from '@/models/PageContentType';
import type { ClientInput, CreateProjectContentInput, CreateProjectInput, ImportBankStatementInput, LeaveInput, LoginInput, UpdateContentInput, UpdateProjectContentInput, UpdateProjectContentStatInput, UpdateProjectContentTypeInput, UpdateProjectInput } from "@/types/request";
import type { GenericResponse, ErrorResponse, PageContentTypeResponse, BrandsResponse, BrandResponse, CategoryResponse, UserResponse, CreateProjectResponse, UserDataResponse, UserSettingResponse, AuthResponse, BillingItemResponse, BankPaymentResponse, DashboardContentsResponse, LeavesDashboardResponse, DashboardIncomesResponse } from "@/types/response";
import { useRoute, useRouter } from 'vue-router'
import Brand from '@/models/Brand';
import Category from '@/models/Category';
import User from '@/models/User';
import Project from '@/models/Project';
import Comment from '@/models/Comment';
import Client from '@/models/Client';
import PackageScope from '@/models/PackageScope';
import Content from '@/models/Content';
import Media from '@/models/Media';
import Page from '@/models/Page';
import router from "@/router"
import Timeline from '@/models/Timeline';
import Setting from '@/models/Setting';
import Leave from '@/models/Leave';
import Paginate from '@/models/Paginate';
import AppNotification from '@/models/AppNotification';
import Note from '@/models/Note';
import ProjectBilling from '@/models/ProjectBilling';
import BillingTodo from '@/models/BillingTodo';
import FlowAccountDocument from '@/models/FlowAccountDocument';
import BillingItem from '@/models/BillingItem';
import ServiceItem from '@/models/ServiceItem';
import BillingPayment from '@/models/BillingPayment';
import ScbStatement from '@/models/ScbStatement';

export const BASE_URL = import.meta.env.VITE_API_BASE_URL || '/api';
// export const BASE_URL = __API_URL__;

const apiService = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    withXSRFToken: true
});

apiService.defaults.headers.common['Content-Type'] = 'application/json';
apiService.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// apiService.interceptors.request.use(config => {
//     const store = useUserStore()
//     if (store.token && config.headers) {
//       config.headers.Authorization = `Bearer ${store.token}`
//     }
//     return config;
// });

apiService.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const store = useStore()
    const userStore = useUserStore()

    if (error.response) {
        const { response } = error;
        if (response.status === 401) {
            // const auth = useAuthStore()
            // setTimeout(async () => await auth.logout(), 3000)
            store.isAuthenticated = false
            userStore.delUser()
            store.intendUrl = router.currentRoute.value ? router.currentRoute.value.path : ""
            router.push('/login');
            return Promise.reject({
                isSuccess: false,
                errorType: "unauthenticated",
                // isBlocked: response.status === 401,
                message: response.data?.message,
            } as ErrorResponse );
        } else if( response.status === 403) {
            return Promise.reject({
                isSuccess: false,
                errorType: "unauthorized",
                // isBlocked: response.status === 403,
                message: response.data?.message,
            } as ErrorResponse );
        } else if( response.status === 429) {
            // request validate failed
            return Promise.reject({
                isSuccess: false,
                errorType: "Too many requests",
                message: response.data?.message,
            } as ErrorResponse );
        }  else if( response.status === 422) {
            // request validate failed
            return Promise.reject({
                isSuccess: false,
                errorType: "unprocessable entity",
                message: response.data?.message,
            } as ErrorResponse );
        } else if (response.status === 419) {
            // no csrf token
            // window.location.reload();
            await sanctumCsrfToken()
            if(axios.isAxiosError(error)) {
                const axiosError = error as AxiosError
                return apiService.request(axiosError.config!)
            }
            return Promise.reject({
                isSuccess: false,
                errorType: "CSRF token mismatch",
                message: "CSRF token mismatch",
            } as ErrorResponse);
        } else if (response.status === 400) {
            // request validate failed
            return Promise.reject({
                isSuccess: false,
                errorType: "Bad request",
                message: response.data?.message,
            } as ErrorResponse);
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        console.log('Error', error.message);
        return Promise.reject({
            isSuccess: false,
            errorType: "no response was received",
            message: error.message,
        } as ErrorResponse);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        return Promise.reject({
            isSuccess: false,
            errorType: typeof error,
            message: error.message,
        } as ErrorResponse);
    }
    console.log(error.config);
    return Promise.reject(error);
  }
);

export const sanctumCsrfToken = async () => {
    const response = await apiService.get<GenericResponse>('sanctum/csrf-cookie');
    return response.data;
}

export const login = async (input: LoginInput): Promise<AuthResponse> => {
  const response = await apiService.post<AuthResponse>('login', input);
  return response.data;
}
export const logout = async () => {
    const response = await apiService.post<GenericResponse>('logout');
    return response.data;
}

export const getUserData = async (): Promise<UserDataResponse> => {
    const response = await apiService.get<AxiosResponse<UserDataResponse>>('me/user_data');
    return response.data.data;
}

export const updateProfile = async (formData: FormData): Promise<User> => {
    const response = await apiService.post<AxiosResponse<User>>("me/profile", formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data.data;
}

export const changePassword = async (password: string, passwordConfirmation: string): Promise<GenericResponse> => {
    const response = await apiService.post<GenericResponse>("me/change_password", {
        password: password,
        password_confirmation: passwordConfirmation
    });
    return response.data;
}

export const getScbStatements = async () => {
    const response = await apiService.get<GenericResponse>('/scb/statements');
    return response.data;
}

export const importSCBStatement = async (input: ImportBankStatementInput) => {
    const formData = new FormData();
    formData.append("file", input.file);
    const response = await apiService.post<GenericResponse>('/scb/import', formData, {
        headers: { "Content-Type": "multipart/form-data", "Accept": "application/json" },
    });
    return response.data;
}

export const getBrands = async (keyword?: string | undefined | null): Promise<Brand[] | undefined> => {
    const response = await apiService.get<BrandsResponse>('brands', { params: { keyword: keyword }});
    return response.data.data;
}

export const getClients = async (keyword?: string | undefined | null): Promise<Client[] | undefined> => {
    const response = await apiService.get<AxiosResponse<Client[]>>('clients', { params: { keyword: keyword } });
    return response.data.data;
}

export const createBrand = async (title: string): Promise<Brand> => {
    const response = await apiService.post<BrandResponse>('brand/create', { title: title });
    return response.data.data;
}

export const getPages = async (): Promise<Page[]> => {
    const response = await apiService.get<AxiosResponse<Page[]>>('pages');
    return response.data.data;
}

export const getPackages = async (): Promise<PackageScope[]> => {
    const response = await apiService.get<AxiosResponse<PackageScope[]>>('packages');
    return response.data.data;
}

export const getCategories = async (): Promise<Category[]> => {
    const response = await apiService.get<CategoryResponse>('categories');
    return response.data.data;
}

export const getContentUsers = async (): Promise<User[]> => {
    const response = await apiService.get<UserResponse>('users/content');
    return response.data.data;
}

export const getUsers = async (): Promise<User[]> => {
    const response = await apiService.get<UserResponse>('users');
    return response.data.data;
}

export const getTimelineActions = async (): Promise<string[]> => {
    const response = await apiService.get<AxiosResponse<string[]>>('project/actions');
    return response.data.data;
}

export const queryProjects = async (page: number, perPage: number, start?: string, end?: string, keyword?: string, status?: string): Promise<Paginate<Project>> => {
    const response = await apiService.get<AxiosResponse<Paginate<Project>>>(`project/query`, {
        params: {
            page: page,
            per_page: perPage,
            keyword: keyword,
            start_date: start,
            end_date: end,
            status: status,
        }
    });
    return response.data.data;
}

export const kanbanProjects = async (): Promise<Project[]> => {
    const response = await apiService.get<AxiosResponse<Project[]>>(`project/kanban`);
    return response.data.data;
}

export const calendarProjects = async (start: string, end: string, status: string = ""): Promise<Project[]> => {
    const response = await apiService.get<AxiosResponse<Project[]>>(`project/calendar`, {
        params: {
            start_date: start,
            end_date: end,
            status: status
        }
    });
    return response.data.data;
}

export const getPostedForBillingProjects = async (): Promise<Project[]> => {
    const response = await apiService.get<AxiosResponse<Project[]>>(`project/posted/billing`);
    return response.data.data;
}

export const getStatusesCount = async (start?: string, end?: string): Promise<GenericResponse> => {
    const response = await apiService.get<GenericResponse>(`/project/statuses/count`, {
        params: {
            start_date: start,
            end_date: end,
        }
    });
    return response.data;
}

export const changeProjectStatus = async (projectNumber: string, status: string): Promise<Project> => {
    const response = await apiService.put<AxiosResponse<Project>>(`project/${projectNumber}/status`, {
        status: status,
    });
    return response.data.data;
}

export const updateProjectBoardIndexes = async (indexes: CardIndex[]): Promise<GenericResponse> => {
    const response = await apiService.put<GenericResponse>(`project/board/indexes`, {
        indexes: indexes
    });
    return response.data;
}


export const createProject = async (input: CreateProjectInput): Promise<Project> => {
    const response = await apiService.post<CreateProjectResponse>('project/create', input);
    return response.data.data;
}

export const updateProject = async (projectNumber: string, input: UpdateProjectInput): Promise<Project> => {
    console.log(input)
    const response = await apiService.put<AxiosResponse<Project>>(`project/${projectNumber}`, input);
    return response.data.data;
}

export const deleteProject = async (projectNumber: string): Promise<GenericResponse> => {
    const response = await apiService.delete<AxiosResponse<GenericResponse>>(`project/${projectNumber}`)
    return response.data.data
}

export const createProjectContent = async (projectNumber: string, input: CreateProjectContentInput): Promise<Content> => {
    const response = await apiService.post<AxiosResponse<Content>>(`project/${projectNumber}/content/create`, input);
    return response.data.data;
}

export const getProject = async (projectNumber: string): Promise<Project> => {
    const response = await apiService.get<AxiosResponse<Project>>(`project/${projectNumber}`);
    return response.data.data;
}

export const getProjectContents = async (projectNumber: string): Promise<Content[]> => {
    const response = await apiService.get<AxiosResponse<Content[]>>(`project/${projectNumber}/contents`);
    return response.data.data;
}

export const getProjectTimelines = async (projectNumber: string): Promise<Timeline[]> => {
    const response = await apiService.get<AxiosResponse<Timeline[]>>(`project/${projectNumber}/timelines`);
    return response.data.data;
}

export const getProjectFiles = async (projectNumber: string): Promise<Media[]> => {
    const response = await apiService.get<AxiosResponse<Media[]>>(`project/${projectNumber}/files`);
    return response.data.data;
}

export const projectRemoveFile = async (projectNumber: string, mediaId: number): Promise<GenericResponse> => {
    const response = await apiService.put<GenericResponse>(`project/${projectNumber}/file/remove/${mediaId}`);
    return response.data;
}

export const updateProjectContent = async (content_id: number, input: UpdateProjectContentInput): Promise<Content> => {
    const response = await apiService.put<AxiosResponse<Content>>(`/project/content/${content_id}/update`, input);
    return response.data.data;
}

export const updateProjectContentType = async (content_id: number, input: UpdateProjectContentTypeInput): Promise<Content> => {
    const response = await apiService.put<AxiosResponse<Content>>(`/project/content/${content_id}/type`, input);
    return response.data.data;
}

export const updateProjectContentStat = async (content_id: number, input: UpdateProjectContentStatInput): Promise<Content> => {
    const response = await apiService.put<AxiosResponse<Content>>(`/project/content/${content_id}/stat`, input);
    return response.data.data;
}


export const getProjectComments = async (projectNumber: string): Promise<Comment[]> => {
    const response = await apiService.get<AxiosResponse<Comment[]>>(`project/${projectNumber}/comments`);
    return response.data.data;
}

export const postProjectComment = async (projectNumber: string, comment: string): Promise<Comment[]> => {
    const response = await apiService.post<AxiosResponse<Comment[]>>(`project/${projectNumber}/comment`, {
        comment: comment
    });
    return response.data.data;
}

export const postProjectImageComment = async (projectNumber: string, formData: FormData): Promise<Comment[]> => {
    const response = await apiService.post<AxiosResponse<Comment[]>>(`project/${projectNumber}/comment/images`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data.data;
}

export const deleteProjectComment = async (projectNumber: string, comment: Comment): Promise<GenericResponse> => {
    const response = await apiService.delete<AxiosResponse<GenericResponse>>(`project/${projectNumber}/comment/${comment.id}`)
    return response.data.data
}

export const queryContents = async (page: number, perPage: number, start?: string, end?: string, keyword?: string, status?: string, source?: string, user?: User): Promise<Paginate<Content>> => {
    const response = await apiService.get<AxiosResponse<Paginate<Content>>>(`content/query`, {
        params: {
            page: page,
            per_page: perPage,
            keyword: keyword,
            start_date: start,
            end_date: end,
            status: status,
            source: source,
            user_id: user?.id
        }
    });
    return response.data.data;
}

export const queryMyContents = async (page: number, perPage: number, start?: string, end?: string, keyword?: string, source?: string): Promise<Paginate<Content>> => {
    const response = await apiService.get<AxiosResponse<Paginate<Content>>>(`content/me/query`, {
        params: {
            page: page,
            per_page: perPage,
            keyword: keyword,
            start_date: start,
            end_date: end,
            source: source,
        }
    });
    return response.data.data;
}

export const unfinishedContents = async (page: number, perPage: number, source?: string): Promise<Paginate<Content>> => {
    const response = await apiService.get<AxiosResponse<Paginate<Content>>>(`content/unfinished`, {
        params: {
            page: page,
            per_page: perPage,
            source: source,
        }
    });
    return response.data.data;
}

export const myContentCalendar = async (start?: string, end?: string, source?: string): Promise<Content[]> => {
    const response = await apiService.get<AxiosResponse<Content[]>>(`content/me/calendar`, {
        params: {
            start_date: start,
            end_date: end,
            source: source,
        }
    });
    return response.data.data;
}

export const createContent = async (input: UpdateContentInput): Promise<Content> => {
    const response = await apiService.post<AxiosResponse<Content>>('content/create', input);
    return response.data.data;
}

export const getContent = async (number: string): Promise<Content> => {
    const response = await apiService.get<AxiosResponse<Content>>(`content/${number}`);
    return response.data.data;
}

export const updateContent = async (number: string, input: UpdateContentInput): Promise<Content> => {
    const response = await apiService.put<AxiosResponse<Content>>(`/content/${number}`, input);
    return response.data.data;
}

export const changeContentStatus = async (contentNumber: string, status: string): Promise<Content> => {
    const response = await apiService.put<AxiosResponse<Content>>(`content/${contentNumber}/status`, {
        status: status
    });
    return response.data.data;
}

export const calendarContents = async (start: string, end: string, source?: string): Promise<Content[]> => {
    const response = await apiService.get<AxiosResponse<Content[]>>(`content/calendar`, {
        params: {
            start_date: start,
            end_date: end,
            source: source,
        }
    });
    return response.data.data;
}

export const getMyTodo = async (): Promise<Timeline[]> => {
    const response = await apiService.get<AxiosResponse<Timeline[]>>(`timeline/mytodo`);
    return response.data.data;
}

export const getMyTodoHistory = async (): Promise<Timeline[]> => {
    const response = await apiService.get<AxiosResponse<Timeline[]>>(`timeline/mytodo/history`);
    return response.data.data;
}

export const updateTodo = async (timeline: Timeline, isComplete: boolean): Promise<Timeline> => {
    const response = await apiService.put<AxiosResponse<Timeline>>(`timeline/${timeline.id}/update`, {
        is_complete: isComplete
    });
    return response.data.data;
}

export const createLeave = async (input: LeaveInput): Promise<Leave> => {
    const response = await apiService.post<AxiosResponse<Leave>>('leave/create', input);
    return response.data.data;
}

export const updateLeave = async (leave: Leave, input: LeaveInput): Promise<Leave> => {
    const response = await apiService.put<AxiosResponse<Leave>>(`leave/${leave.id}/update`, input);
    return response.data.data;
}

export const deleteLeave = async (leave: Leave): Promise<Leave> => {
    const response = await apiService.delete<AxiosResponse>(`leave/${leave.id}/delete`);
    return response.data.data;
}

export const queryLeave = async (page: number, perPage: number): Promise<Paginate<Leave>> => {
    const response = await apiService.get<AxiosResponse<Paginate<Leave>>>(`leave/query`, {
        params: {
            page: page,
            per_page: perPage,
        }
    })
    return response.data.data;
}
export const queryLeaveCalendar = async (start: string, end: string): Promise<Leave[]> => {
    const response = await apiService.get<AxiosResponse<Leave[]>>(`leave/calendar`, {
        params: {
            start_date: start,
            end_date: end,
        }
    })
    return response.data.data;
}

export const getLeavesDashboardData = async (year: number): Promise<LeavesDashboardResponse> => {
    const response = await apiService.get<AxiosResponse<LeavesDashboardResponse>>(`leave/dashboard`, {
        params: {
            year: year,
        }
    })
    return response.data.data;
}

export const getUserLeavesData = async (year: number): Promise<OpenObject> => {
    const response = await apiService.get<AxiosResponse<OpenObject>>(`leave/user`, {
        params: {
            year: year,
        }
    })
    return response.data.data;
}

export const createClient = async (input: ClientInput): Promise<Client> => {
    const response = await apiService.post<AxiosResponse<Client>>('client/create', input);
    return response.data.data;
}

export const updateClient = async (client: Client, input: ClientInput): Promise<Client> => {
    const response = await apiService.put<AxiosResponse<Client>>(`client/${client.id}/update`, input);
    return response.data.data;
}

export const deleteClient = async (client: Client): Promise<GenericResponse> => {
    const response = await apiService.delete<AxiosResponse<GenericResponse>>(`client/${client.id}/delete`);
    return response.data.data;
}

export const queryClient = async (page: number, perPage: number, keyword?: string): Promise<Paginate<Client>> => {
    const response = await apiService.get<AxiosResponse<Paginate<Client>>>(`client/query`, {
        params: {
            keyword: keyword,
            page: page,
            per_page: perPage,
        }
    })
    return response.data.data;
}


export const getSetting = async (): Promise<Setting> => {
    const response = await apiService.get<AxiosResponse<Setting>>(`setting`);
    return response.data.data;
}

export const updateSetting = async (formData: FormData): Promise<Setting> => {
    const response = await apiService.post<AxiosResponse<Setting>>(`setting`, formData);
    return response.data.data;
}

export const getUserSetting = async (perPage: number = 10): Promise<UserSettingResponse> => {
    const response = await apiService.get<AxiosResponse<UserSettingResponse>>(`me/setting`);
    return response.data.data;
}


export const getNotifications = async (perPage: number = 10): Promise<Paginate<AppNotification>> => {
    const response = await apiService.get<Paginate<AppNotification>>(`me/notifications`, {
        params: {
            per_page: perPage,
        }
    });
    return response.data;
}

export const shouldAlertUnfinishedContent = async (): Promise<boolean> => {
    const response = await apiService.get<AxiosResponse<OpenObject>>(`me/should_alert_unfinished_content`);
    const shouldAlert = response.data.data.should_alert ?? false
    return shouldAlert;
}

export const logUserAction = async (log: string): Promise<GenericResponse> => {
    const response = await apiService.post<GenericResponse>(`me/log_action`, {
        log: log
    });
    return response.data
}

export const updateFcmToken = async (fcmToken: string): Promise<GenericResponse> => {
    const response = await apiService.post<GenericResponse>(`me/web_fcm_token/update`, {
        fcm_token: fcmToken,
    });
    return response.data;
}


export const testFcm = async (): Promise<GenericResponse> => {
    const response = await apiService.post<GenericResponse>(`me/fcm/test`);
    return response.data;
}

export const createNote = async (is_public: boolean, title: string, body?: string): Promise<Note> => {
    const response = await apiService.post<AxiosResponse<Note>>('note/create', {
        title: title,
        body: body,
        is_public: is_public,
    });
    return response.data.data;
}

export const updateNote = async (note: Note, is_public: boolean, title: string, body?: string): Promise<Note> => {
    const response = await apiService.put<AxiosResponse<Note>>(`note/${note.id}/update`, {
        title: title,
        body: body,
        is_public: is_public,
    });
    return response.data.data;
}

export const deleteNote = async (note: Note): Promise<GenericResponse> => {
    const response = await apiService.delete<AxiosResponse<GenericResponse>>(`note/${note.id}/delete`);
    return response.data.data;
}

export const getNote = async (noteId: number): Promise<Note> => {
    const response = await apiService.get<AxiosResponse<Note>>(`note/${noteId}`)
    return response.data.data;
}

export const queryNote = async (keyword?: string): Promise<Paginate<Note>> => {
    const response = await apiService.get<AxiosResponse<Paginate<Note>>>(`note/query`, {
        params: {
            keyword: keyword,
            page: 1,
            per_page: 1000,
        }
    })
    return response.data.data;
}

export const getBillingTodoActions = async (): Promise<string[]> => {
    const response = await apiService.get<AxiosResponse<string[]>>('billing/actions');
    return response.data.data;
}

export const queryBillings = async (page: number, perPage: number, keyword?: string, start?: string, end?: string, status?: string): Promise<Paginate<ProjectBilling>> => {
    const response = await apiService.get<AxiosResponse<Paginate<ProjectBilling>>>(`billing/query`, {
        params: {
            page: page,
            per_page: perPage,
            keyword: keyword,
            start_date: start,
            end_date: end,
            status: status,
        }
    });
    return response.data.data;
}

export const queryPendingValidationBillings = async (page: number, perPage: number, keyword?: string): Promise<Paginate<ProjectBilling>> => {
    const response = await apiService.get<AxiosResponse<Paginate<ProjectBilling>>>(`billing/pending/query`, {
        params: {
            page: page,
            per_page: perPage,
            keyword: keyword,
        }
    });
    return response.data.data;
}

export const boardBillings = async (): Promise<ProjectBilling[]> => {
    const response = await apiService.get<AxiosResponse<ProjectBilling[]>>(`billing/board`);

    return response.data.data;
}

export const calendarBillings = async (start: string, end: string): Promise<ProjectBilling[]> => {
    const response = await apiService.get<AxiosResponse<ProjectBilling[]>>(`billing/calendar`, {
        params: {
            start_date: start,
            end_date: end,
        }
    });
    return response.data.data;
}

export const getAllBillingTodos = async (): Promise<BillingTodo[]> => {
    const response = await apiService.get<AxiosResponse<BillingTodo[]>>(`billing/todos`);
    return response.data.data;
}

export const getBillingStatusesCount = async (start?: string, end?: string): Promise<GenericResponse> => {
    const response = await apiService.get<GenericResponse>(`/billing/statuses/count`, {
        params: {
            start_date: start,
            end_date: end,
        }
    });
    return response.data;
}

export const changeBillingStatus = async (billingNumber: string, status: string): Promise<ProjectBilling> => {
    const response = await apiService.put<AxiosResponse<ProjectBilling>>(`billing/${billingNumber}/status`, {
        status: status,
    });
    return response.data.data;
}

export const updateBillingBoardIndexes = async (indexes: CardIndex[]): Promise<GenericResponse> => {
    const response = await apiService.put<GenericResponse>(`billing/board/indexes`, {
        indexes: indexes
    });
    return response.data;
}

export const startBilling = async (projectNumber: string, index: number): Promise<ProjectBilling> => {
    const response = await apiService.post<AxiosResponse<ProjectBilling>>(`project/${projectNumber}/billing/create`, {
        index: index,
    });
    return response.data.data;
}

export const getBilling = async (billingNumber: string): Promise<ProjectBilling> => {
    const response = await apiService.get<AxiosResponse<ProjectBilling>>(`billing/${billingNumber}`);
    return response.data.data;
}

export const updateBilling = async (billingNumber: string, params: OpenObject): Promise<ProjectBilling> => {
    const response = await apiService.put<AxiosResponse<ProjectBilling>>(`billing/${billingNumber}`, params);
    return response.data.data;
}

export const getBillingTodos = async (billingNumber: string): Promise<BillingTodo[]> => {
    const response = await apiService.get<AxiosResponse<BillingTodo[]>>(`billing/${billingNumber}/todos`);
    return response.data.data;
}

export const createBillingTodo = async (billingNumber: string, params: OpenObject): Promise<BillingTodo> => {
    const response = await apiService.post<AxiosResponse<BillingTodo>>(`billing/${billingNumber}/todo`, params);
    return response.data.data;
}

export const updateBillingTodo = async (billingTodo: BillingTodo, params: OpenObject): Promise<BillingTodo> => {
    const response = await apiService.put<AxiosResponse<BillingTodo>>(`billing_todo/${billingTodo.id}`, params);
    return response.data.data;
}

export const toggleBillingTodo = async (billingTodo: BillingTodo, params: OpenObject): Promise<BillingTodo> => {
    const response = await apiService.put<AxiosResponse<BillingTodo>>(`billing_todo/${billingTodo.id}`, params);
    return response.data.data;
}

export const deleteBillingTodo = async (billingTodo: BillingTodo): Promise<GenericResponse> => {
    const response = await apiService.delete<GenericResponse>(`billing_todo/${billingTodo.id}`);
    return response.data;
}

export const getBillingItems = async (billingNumber: string): Promise<BillingItem[]> => {
    const response = await apiService.get<AxiosResponse<BillingItem[]>>(`billing/${billingNumber}/items`);
    return response.data.data;
}

export const createBillingItem = async (billingNumber: string, params: OpenObject): Promise<BillingItemResponse> => {
    const response = await apiService.post<AxiosResponse<BillingItemResponse>>(`billing/${billingNumber}/item`, params);
    return response.data.data;
}

export const updateBillingItem = async (billingItem: BillingItem, params: OpenObject): Promise<BillingItemResponse> => {
    const response = await apiService.put<AxiosResponse<BillingItemResponse>>(`billing_item/${billingItem.id}`, params);
    return response.data.data;
}

export const deleteBillingItem = async (billingItem: BillingItem): Promise<GenericResponse> => {
    const response = await apiService.delete<GenericResponse>(`billing_item/${billingItem.id}`);
    return response.data;
}

export const getBillingFiles = async (billingNumber: string): Promise<Media[]> => {
    const response = await apiService.get<AxiosResponse<Media[]>>(`billing/${billingNumber}/files`);
    return response.data.data;
}

export const billingRemoveFile = async (billingNumber: string, mediaId: number): Promise<GenericResponse> => {
    const response = await apiService.put<GenericResponse>(`billing/${billingNumber}/file/remove/${mediaId}`);
    return response.data;
}

export const addFlowAccountDocument = async (billingNumber: string, params: OpenObject): Promise<FlowAccountDocument> => {
    const response = await apiService.post<AxiosResponse<FlowAccountDocument>>(`billing/${billingNumber}/flow_account`, params);
    return response.data.data;
}

export const updateFlowAccountDocument = async (document: FlowAccountDocument, params: OpenObject): Promise<FlowAccountDocument> => {
    const response = await apiService.put<AxiosResponse<FlowAccountDocument>>(`flow_account_document/${document.id}`, params);
    return response.data.data;
}

export const deleteFlowAccountDocument = async (document: FlowAccountDocument): Promise<GenericResponse> => {
    const response = await apiService.delete<GenericResponse>(`flow_account_document/${document.id}`);
    return response.data;
}

export const getBillingPayments = async (billingNumber: string): Promise<BillingPayment[]> => {
    const response = await apiService.get<AxiosResponse<BillingPayment[]>>(`billing/${billingNumber}/payments`);
    return response.data.data;
}

export const createBillingPayment = async (billingNumber: string, params: OpenObject): Promise<BillingPayment> => {
    const response = await apiService.post<AxiosResponse<BillingPayment>>(`billing/${billingNumber}/payment`, params);
    return response.data.data;
}

export const updateBillingPayment = async (billingPayment: BillingPayment, params: OpenObject): Promise<BillingPayment> => {
    const response = await apiService.put<AxiosResponse<BillingPayment>>(`billing/payment/${billingPayment.id}`, params);
    return response.data.data;
}

export const deleteBillingPayment = async (billingPayment: BillingPayment): Promise<GenericResponse> => {
    const response = await apiService.delete<GenericResponse>(`billing/payment/${billingPayment.id}`);
    return response.data;
}

export const getServiceItems = async (): Promise<ServiceItem[]> => {
    const response = await apiService.get<AxiosResponse<ServiceItem[]>>(`service_item`);
    return response.data.data;
}

export const getBillingPaymentFiles = async (billingNumber: string): Promise<Media[]> => {
    const response = await apiService.get<AxiosResponse<Media[]>>(`billing/${billingNumber}/payment/files`);
    return response.data.data;
}

export const billingRemovePaymentFile = async (billingNumber: string, mediaId: number): Promise<GenericResponse> => {
    const response = await apiService.put<GenericResponse>(`billing/${billingNumber}/payment/file/remove/${mediaId}`);
    return response.data;
}

export const addServiceItem = async (params: OpenObject): Promise<ServiceItem> => {
    const response = await apiService.post<AxiosResponse<ServiceItem>>(`service_item/create`, params);
    return response.data.data;
}

export const getBillingScbStatements = async (billingNumber: string): Promise<ScbStatement[]> => {
    const response = await apiService.get<AxiosResponse<ScbStatement[]>>(`billing/${billingNumber}/scb_statements`);
    return response.data.data;
}
export const deleteBillingScbStatement = async (billingNumber: string, statement: ScbStatement): Promise<ScbStatement[]> => {
    const response = await apiService.delete<AxiosResponse<ScbStatement[]>>(`billing/${billingNumber}/scb_statement/${statement.id}`);
    return response.data.data;
}

export const updateServiceItem = async (serviceItem: ServiceItem, params: OpenObject): Promise<ServiceItem> => {
    const response = await apiService.put<AxiosResponse<ServiceItem>>(`service_item/${serviceItem.id}`, params);
    return response.data.data;
}

export const deleteServiceItem = async (serviceItem: ServiceItem): Promise<GenericResponse> => {
    const response = await apiService.delete<GenericResponse>(`service_item/${serviceItem.id}`);
    return response.data;
}

export const billingTrialValidate = async (billingNumber: string, scb_statement_hash: string): Promise<OpenObject> => {
    const response = await apiService.post<AxiosResponse<OpenObject>>(`billing/validate/trial/${billingNumber}/statement/${scb_statement_hash}`);
    return response.data.data;
}

export const getBankPaymentData = async (): Promise<BankPaymentResponse> => {
    const response = await apiService.get<AxiosResponse<BankPaymentResponse>>(`me/payment_data`);
    return response.data.data;
}

export const getDashboardContents = async (start: string, end: string): Promise<DashboardContentsResponse> => {
    const response = await apiService.get<AxiosResponse<DashboardContentsResponse>>(`dashboard/contents`, {
        params: {
            start_date: start,
            end_date: end,
        }
    });
    return response.data.data;
}

export const getDashboardIncomes = async (start: string, end: string): Promise<DashboardIncomesResponse> => {
    const response = await apiService.get<AxiosResponse<DashboardIncomesResponse>>(`dashboard/incomes`, {
        params: {
            start_date: start,
            end_date: end,
        }
    });
    return response.data.data;
}

export const getLobbyRoom = async (): Promise<OpenObject> => {
    const response = await apiService.get<AxiosResponse<OpenObject>>(`whale-chess/room/list`);
    return response.data.data;
}

export const createChessRoom = async (): Promise<OpenObject> => {
    const response = await apiService.post<AxiosResponse<OpenObject>>(`whale-chess/room/create`);
    return response.data.data;
}

export const deleteChessRoom = async (roomId: string): Promise<OpenObject> => {
    const response = await apiService.delete<AxiosResponse<OpenObject>>(`whale-chess/room/delete/${roomId}`);
    return response.data.data;
}

export const exitChessRoom = async (room: string): Promise<OpenObject> => {
    const response = await apiService.post<AxiosResponse<OpenObject>>(`whale-chess/room/exit/${room}`);
    return response.data.data;
}

export const joinChessRoom = async (roomId: string): Promise<OpenObject> => {
    const response = await apiService.post<AxiosResponse<OpenObject>>(`whale-chess/room/join/${roomId}`);
    return response.data.data;
}

export const getChessRoomData = async (roomKey: string): Promise<OpenObject> => {
    const response = await apiService.get<AxiosResponse<OpenObject>>(`whale-chess/room/${roomKey}`);
    return response.data.data;
}

export const playWhaleChess = async (roomKey: string, position: number, size: string): Promise<OpenObject> => {
    const response = await apiService.post<AxiosResponse<OpenObject>>(`whale-chess/room/${roomKey}/play`, {
        position: position,
        size: size,
    });
    return response.data.data;
}

export default {
    sanctumCsrfToken,
    login,
    logout,
    getScbStatements,
    importSCBStatement
};

// export const signUpUserFn = async (user: ISignUpInput) => {
//   const response = await authApi.post<GenericResponse>('auth/register', user);
//   return response.data;
// };

// export const loginUserFn = async (user: ILoginInput) => {
//   const response = await authApi.post<ILoginResponse>('auth/login', user);
//   return response.data;
// };

// export const verifyEmailFn = async (verificationCode: string) => {
//   const response = await authApi.get<GenericResponse>(
//     `auth/verifyemail/${verificationCode}`
//   );
//   return response.data;
// };

// export const logoutUserFn = async () => {
//   const response = await authApi.get<GenericResponse>('auth/logout');
//   return response.data;
// };

// export const getMeFn = async () => {
//   const response = await authApi.get<IUserResponse>('users/me');
//   return response.data;
// };
