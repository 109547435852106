<template>
    <button
        type="button"
        ref="el"
        class=""
        :class="{'isloading' : loading, 'disabled': isDisabled, 'is-left': isLeft, 'is-right': isRight }"
        :disable="isDisabled"
        @click="onClick"
    >
        <div class="loadlabel position-relative" >
            <Transition name="anim">
                <div v-if="props.loading && isLeft" class="left-col me-2 d-flex align-items-center">
                    <div  class="spinner-border loader-sm"></div>
                </div>
            </Transition>
            <div>
                <div class="" :class="{'invisible': (isOverlayCenter && loading) }"><slot></slot></div>
                <div v-if="props.loading && isOverlayCenter" class="d-flex align-items-center position-absolute top-50 start-50 translate-middle">
                    <div  class="spinner-border loader-sm"></div>
                </div>
            </div>
            <Transition name="anim">
                <div v-if="props.loading && isRight" class="right-col ms-2 d-flex align-items-center">
                    <div  class="spinner-border loader-sm"></div>
                </div>
            </Transition>
        </div>
    </button>
</template>

<script setup lang="ts">
    import { ref, computed } from 'vue'
    import "@/assets/cork/sass/authentication/auth-boxed.scss";

    defineOptions({
        name: 'LoadingIndicatorButton',
        // inheritAttrs: false,
        customOptions: {
            /* ... */
        }
    })

    const el = ref()
    const props = defineProps({
        loading: { type: Boolean, required: true },
        position: { type: String, required: false, default: 'right' },
    })
    const emit = defineEmits(['click']);

    const isDisabled = computed( () => props.loading )
    const isLeft = computed(() => props.position == 'left' )
    const isRight = computed(() => props.position == 'right')
    const isOverlayCenter = computed(() => props.position == 'center')

    function onClick(e: any) {
        emit('click');
        el.value.blur()
    }
</script>

<style lang="scss" scoped>
.mright {
    margin-right: 1rem;
}
.anim-enter-active,
.anim-leave-active {
  transition: opacity .5s ease;
}

.anim-enter-from,
.anim-leave-to {
  opacity: 0;
}
.left-col {
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
}
.right-col {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
}
.bttn {
	position:relative;
	transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.loadlabel {
	position: relative;
}
.isloading {
    &.is-left {
        padding-left: 3.5rem !important;
        padding-right: 2rem !important;
    }
    &.is-right {
        padding-left: 2rem !important;
        padding-right: 3.5rem !important;
    }
}
.spinnerloader {
	position: absolute;
	right: 1rem;
	/* transition: 2s ease; */
}
.mleft {
	margin-left: 1rem;
}
</style>
