// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, onMessage, isSupported } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCbRhcJHTK7WAZNihxWNb5BaXBM8k1rovM",
  authDomain: "whaleplan-1788d.firebaseapp.com",
  projectId: "whaleplan-1788d",
  storageBucket: "whaleplan-1788d.appspot.com",
  messagingSenderId: "448838556034",
  appId: "1:448838556034:web:b3f80a650a3cd66d0a179b",
  measurementId: "G-E2YNFR5X02"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const analytics = getAnalytics(firebaseApp);
// Initialize Firebase Cloud Messaging and get a reference to the service
isSupported().then( isMessagingSupported => {
    if(isMessagingSupported) {
        const messaging = getMessaging(firebaseApp);
        onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
            navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
                registration?.showNotification(
                    payload.notification?.title ?? 'title',
                    payload.notification
                )
            });
        });
    }
})

