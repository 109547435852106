<template>
    <div class="loading-container">
    	<div class="loading"> 
    	    <div class="d-flex justify-content-between mx-5 mt-3 mb-5">
    	        <div class="spinner-border text-info align-self-center">Loading...</div>
    	    </div>
    	    <div class="align-self-center">...Loading...</div>
    	</div>
    </div>
</template>

<script setup lang="ts">

</script>

<style lang="scss">

.loading-container {
	margin: 0;
	display: flex;
	position: absolute;
	min-width: 320px;
	min-height: 100vh;
	left: 0;
	right: 0;
	.loading {
		margin: auto;
	    padding: 2rem;
	    text-align: center;
	}
}
</style>