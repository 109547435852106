<template>
    <div class="dropdown nav-item notification-dropdown btn-group">
        <a href="javascript:;" id="ddlnotify" data-bs-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle btn-icon-only nav-link">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell">
                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
            </svg>
            <span class="badge badge-success"></span>
        </a>
        <ul class="dropdown-menu dropdown-menu-right m-0" aria-labelledby="ddlnotify">
            <template v-for="(item, index) in notifications">
                <li role="presentation">
                    <a href="javascript:;" class="dropdown-item" style="padding-top: 0 !important; padding-bottom: 0 !important;">
                        <div class="media">
                            <div class="media-aside align-self-center">
                                <i v-if="item.type == 'App\\Notifications\\ProjectCommentAdded'" class="bi bi-chat-dots"></i>
                                <i v-else-if="item.type == 'App\\Notifications\\ProjectTimelineInvolved'" class="bi bi-calendar-check"></i>
                            </div>
                            <div class="w-100">
                                <div class="fw-bold">{{ item.data.title }}</div>
                                <div class="">{{ useTextEllipsis(item.data.body) }}</div>
                                <div class="fs-07 text-end">{{ useDayAgo(item.created_at) }}</div>
                            </div>
                        </div>
                    </a>
                </li>
                <li role="presentation">
                    <hr role="separator" aria-orientation="horizontal" size="0" class="dropdown-divider my-1 mx-0" />
                </li>
            </template>
            <li role="presentation" class="d-flex justify-content-center">
                <router-link :to="{ name: 'notification.index' }" class="px-2 fs-07 w-100 text-center">See all</router-link>
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import User from '@/models/User';
import { getNotifications } from "@/services/api/http";
import { useToast } from 'vue-toastification';
import { useDayAgo, useTextEllipsis } from '@/composables/utility';
import Paginate from '@/models/Paginate';
import AppNotification from '@/models/AppNotification';

const toast = useToast()
const paginate = ref<Paginate<AppNotification>>()
const notifications = computed(() => {
    return paginate.value?.data
})

const loading = ref(false)
const fetchData = async (page: number = 1) => {
    loading.value = true
    try {
        const data = await getNotifications()
        paginate.value = data
    }
    catch (err: any) {
        toast.error(err.message, {
            timeout: 2000
        });
    }
    finally {
        loading.value = false
    }

}

const init = () => {
    fetchData()
}

onMounted(() => {
    init()
})

</script>
