<template>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <nav ref="menu" id="sidebar">
            <div class="shadow-bottom"></div>

            <perfect-scrollbar class="list-unstyled menu-categories" tag="ul"
                :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }">
                <li class="menu" v-for="item in menus">
                    <router-link class="dropdown-toggle" v-if="item.children == null || item.children.length <= 0" :to="{ path: item.router! }"
                        @click="toggleMobileMenu">
                        <div class="word-break-all white-space-normal">
                            <i :class="item.icon"></i> {{ item.title }}
                        </div>
                    </router-link>
                    <a v-else class="dropdown-toggle" data-bs-toggle="collapse" :data-bs-target="`#_${item.id}`"
                        :aria-controls="item.id" aria-expanded="false">
                        <div class="">
                            <span class="word-break-all white-space-normal"><i :class="item.icon"></i> {{ item.title
                                }}</span>
                        </div>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-chevron-right">
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </div>
                    </a>
                    <ul :id="`_${item.id}`" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
                        <li v-for="child in item.children">
                            <router-link :to="{ path: child.router! }" @click="toggleMobileMenu">
                                <div class="word-break-all white-space-normal"><i :class="child.icon"></i> {{
                                    child.title }}</div>
                            </router-link>
                        </li>
                    </ul>
                </li>
            </perfect-scrollbar>
        </nav>
    </div>
    <!--  END SIDEBAR  -->
</template>

<script setup lang="ts">
import { onMounted, watch, ref } from 'vue';
import useStore from '@/stores'
import { useMenuStore } from '@/stores/menu';
const store = useStore();
const menuStore = useMenuStore();
let menus = menuStore.authorizedMenus


const menu_collapse = ref('dashboard');

onMounted(() => {
    const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
    if (selector) {
        const ul = selector.closest('ul.collapse');
        if (ul != null) {
            let ele = ul.closest('li.menu')?.querySelectorAll('.dropdown-toggle');
            if (ele) {
                const element = ele[0];
                setTimeout(() => {
                    (element as HTMLElement).click();
                });
            }
        } else {
            (selector as HTMLElement).click();
        }
    }
});

const toggleMobileMenu = () => {
    if (window.innerWidth < 991) {
        // store.toggleSideBar();
    }
};
</script>
