import User from '@/models/User';
import { defineStore } from 'pinia'
import { getBankPaymentData } from '@/services/api/http';
import BankAccount from '@/models/BankAccount';
import Bank from '@/models/Bank';

const callOnces =  {
    bankData: false
}

export default defineStore('default', {
    state: () => {
        return {
            is_show_sidebar: false,
            displayLoading: true,
            isInitialized: false,
            currentRouterLayout: 'default',
            menu_style: "vertical",
            layout_style: "full",
            isAuthenticated: false,
            intendUrl: null as any,
            content_users: [] as User[],
            timeline_actions: [] as string[],
            billing_actions: [] as string[],
            banks: [] as Bank[],
            bankAccounts: [] as BankAccount[]
        }
    },
    getters: {

    },
    actions: {
        setRouterLayout(layout: string) {
            this.currentRouterLayout = layout;
        },
        toggleSideBar() {
            this.is_show_sidebar = !this.is_show_sidebar;
        },
        async fetchBankPaymentData() {
            if(callOnces.bankData) {
                return
            }
            const data = await getBankPaymentData()
            this.banks = data.banks
            this.bankAccounts = data.bank_accounts
            callOnces.bankData = true
        }
    },
});
