import {
    createRouter, createWebHistory ,
    RouteLocationRaw,
    type NavigationGuardNext,
    type RouteLocationNormalized,
} from 'vue-router';

import useStore from './../stores'
import { useUserStore } from '@/stores/user';
import { useMenuStore } from '@/stores/menu';
import Breadcrumb from '@/models/Breadcrumb';

declare module 'vue-router' {
  interface RouteMeta {
    layout?: string,
    label?: string,
    parent?: string,
    only?: string[],
    except?: string[],
  }
}

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Index.vue'),
        meta: {
            label: "Home"
        },
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/dashboard/Index.vue'),
        meta: {
            label: "Dashboard",
            only: ['root','manager']
        },
    },
    {
        path: '/dashboard/income',
        name: 'dashboard.income',
        component: () => import('../views/dashboard/Income.vue'),
        meta: {
            label: "Dashboard Income",
            only: ['root']
        },
    },
    {
        path: '/todo',
        name: 'todo.index',
        component: () => import('../views/todo/Index.vue'),
        meta: {
            label: "Todo"
        },
    },
    {
        path: '/todo/history',
        name: 'todo.history',
        component: () => import('../views/todo/History.vue'),
        meta: {
            label: "Todo History"
        },
    },
    {
        path: '/project/index',
        name: 'project.index',
        component: () => import('../views/project/Index.vue'),
        meta: {
            label: "Projects"
        },
    },
    {
        path: '/project/list',
        name: 'project.list',
        component: () => import('../views/project/List.vue'),
        meta: {
            label: "Project List"
        },
    },
    {
        path: '/project/kanban',
        name: 'project.kanban',
        component: () => import('../views/project/Kanban.vue'),
        meta: {
            label: "Project Board"
        },
    },
    {
        path: '/project/week',
        name: 'project.week',
        component: () => import('../views/project/Week.vue'),
        meta: {
            label: "Project Week"
        },
    },
    {
        path: '/project/calendar',
        name: 'project.calendar',
        component: () => import('../views/project/Calendar.vue'),
        meta: {
            label: "Project Calendar"
        },
    },
    {
        path: '/project/create',
        name: 'project.create',
        component: () => import('../views/project/Create.vue'),
        props: {
            isEdit: false
        },
        meta: {
            parent: 'project.index',
            label: "Create Project"
        },
    },
    {
        path: '/project/:number',
        name: 'project.view',
        component: () => import('../views/project/View.vue'),
        meta: {
            parent: 'project.index',
            label: "Project"
        },
    },
    {
        path: '/project/:number/edit',
        name: 'project.edit',
        component: () => import('../views/project/Create.vue'),
        props: {
            isEdit: true
        },
        meta: {
            parent: 'project.index',
            label: "Project"
        },
    },
    {
        path: '/content/index',
        name: 'content.index',
        component: () => import('../views/content/Index.vue'),
        meta: {
            label: "Contents"
        },
    },
    {
        path: '/content/me',
        name: 'content.me',
        component: () => import('../views/content/Me.vue'),
        meta: {
            label: "My Content"
        },
    },
    {
        path: '/content/unfinished',
        name: 'content.unfinished',
        component: () => import('../views/content/Unfinished.vue'),
        meta: {
            label: "งานที่ค้างคา"
        },
    },
    {
        path: '/content/create',
        name: 'content.create',
        component: () => import('../views/content/Form.vue'),
        props: {
            isEdit: false
        },
        meta: {
            parent: 'content.index',
            label: "Create Content"
        },
    },
    {
        path: '/content/:number',
        name: 'content.view',
        component: () => import('../views/content/Form.vue'),
        props: {
            isEdit: true
        },
        meta: {
            parent: 'content.index',
            label: "Content"
        },
    },
    {
        path: '/content/calendar',
        name: 'content/calendar',
        component: () => import('../views/content/Calendar.vue'),
        meta: {
            label: "Content Calendar"
        },
    },
    {
        path: '/client',
        name: 'client.index',
        component: () => import('../views/client/Index.vue'),
        meta: {
            label: "รายชื่อลูกค้า"
        },
    },
    {
        path: '/billing',
        name: 'billing',
        // component: () => import('../views/scb/Statement.vue'),
        children: [
            {
                path: 'index',
                name: 'billing.index',
                component: () => import('../views/billing/Index.vue'),
                meta: {
                    label: "รายการเก็บเงินทั้งหมด"
                },
            },
            {
                path: 'board',
                name: 'billing.board',
                component: () => import('../views/billing/Board.vue'),
                meta: {
                    label: "Billing Board"
                },
            },
            {
                path: 'dashboard',
                name: 'billing.dashboard',
                component: () => import('../views/billing/Index.vue'),
                meta: {
                    label: "ภาพรวมการเก็บเงิน"
                },
            },
            {
                path: ':number/view',
                name: 'billing.view',
                component: () => import('../views/billing/View.vue'),
                meta: {
                    label: "Billing"
                },
            },
            {
                path: 'todo',
                name: 'billing.todo',
                component: () => import('../views/billing/Todo.vue'),
                meta: {
                    label: "Todo"
                },
            },
            {
                path: 'calendar',
                name: 'billing.calendar',
                component: () => import('../views/billing/Calendar.vue'),
                meta: {
                    label: "Todo เรียงตามวัน"
                },
            },
            {
                path: 'service_items',
                name: 'billing.service_items',
                component: () => import('../views/billing/ServiceItems.vue'),
                meta: {
                    label: "ของซื้อของขาย"
                },
            },
            {
                path: 'verify',
                name: 'billing.verify',
                component: () => import('../views/billing/Verify.vue'),
                meta: {
                    label: "ยืนยันยอด",
                    only: ['root','manager']
                },
            },
        ],
        meta: {
            only: ['root','manager', 'accountant', 'account_executive']
        },
    },
    {
        path: '/scb',
        name: 'scb',
        // component: () => import('../views/scb/Statement.vue'),
        children: [
            {
                path: 'statement',
                name: 'scb.statement',
                component: () => import('../views/scb/Statement.vue'),
                meta: {
                    label: "SCB Statement"
                },
            },
            {
                path: 'import',
                name: 'scb.import',
                component: () => import('../views/scb/Import.vue'),
                meta: {
                    label: "Import SCB Statement"
                },
            },
        ],
        meta: {
            only: ['root','manager']
        },
    },
    {
        path: '/notes',
        name: 'note',
        // component: () => import('../views/note/Index.vue'),
        children: [
            {
                path: '',
                name: 'note.index',
                component: () => import('../views/note/Index.vue'),
                meta: {
                    label: "All Notes"
                },
            },
            {
                path: ':id',
                name: 'note.view',
                component: () => import('../views/note/View.vue'),
                props: true,
                meta: {
                    parent: 'note.index',
                    label: "Note"
                },
            },
            {
                path: 'create',
                name: 'note.create',
                component: () => import('../views/note/Form.vue'),
                meta: {
                    parent: 'note.index',
                    label: "Create note"
                },
            },
            {
                path: ':id/edit',
                name: 'note.edit',
                component: () => import('../views/note/Form.vue'),
                props: true,
                meta: {
                    parent: 'note.view',
                    label: "Edit"
                },
            },
        ],
    },
    {
        path: '/notifications',
        name: 'notification.index',
        component: () => import('../views/notifications/Index.vue'),
        meta: {
            label: "All Notification"
        },
    },
    {
        path: '/users',
        name: 'users.index',
        component: () => import('../views/user/Index.vue'),
        meta: {
            label: "พนักงานทั้งหมด"
        },
    },
    {
        path: '/leave/dashboard',
        name: 'leave.dashboard',
        component: () => import('../views/leave/Dashboard.vue'),
        meta: {
            only: ['root','manager'],
            label: "วันลา"
        },
    },
    {
        path: '/leave/all',
        name: 'leave.all',
        component: () => import('../views/leave/Index.vue'),
        meta: {
            label: "วันลา"
        },
    },
    {
        path: '/user/profile',
        name: 'user.profile',
        component: () => import('../views/user/Profile.vue'),
        meta: {
            label: "My Profile"
        },
    },
    {
        path: '/user/setting',
        name: 'user.setting',
        component: () => import('../views/user/Setting.vue'),
        meta: {
            label: "Setting"
        },
    },
    {
        path: '/whale-chess/lobby',
        name: 'whale_chess.lobby',
        component: () => import('../views/game/ChessLobby.vue'),
        meta: {
            label: "Whale Chess Lobby",
        },
    },
    {
        path: '/whale-chess/play/:roomKey?',
        name: 'whale_chess.play',
        component: () => import('../views/game/Chess.vue'),
        meta: {
            label: "Whale Chess",
        },
    },
    {
        path: '/setting',
        name: 'setting',
        component: () => import('../views/setting/Index.vue'),
        meta: {
            label: "Setting",
            only: ['root','manager']
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/auth/Login.vue'),
        meta: {
            layout: 'auth'
        },
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('../views/auth/Logout.vue'),
        meta: {
            layout: 'auth'
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () => import('../views/404.vue'),
    }
];

const router = createRouter({
    // mode: 'history',
    history: createWebHistory('/app'),
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    const store = useStore()
    const userStore = useUserStore()
    const menuStore = useMenuStore()

    if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
        store.setRouterLayout('auth')
    } else {
        store.setRouterLayout('default')
    }

    if (
        // make sure the user is authenticated
        !store.isAuthenticated &&
        // ❗️ Avoid an infinite redirect
        to.name !== 'login'
    ) {
        // redirect the user to the login page
        store.intendUrl = to.path
        return { name: 'login' }
    } else if (store.isAuthenticated && to.name === 'login') {
        return { name: 'home' }
    } else if(store.isAuthenticated) {
        const meta = to.meta
        if(meta && meta.only) {
            const only = meta.only
            if(!userStore.hasRoles(only)) {
                return { name: 'home' }
            }
        } else if(meta && meta.except) {
            const except = meta.except
            if(userStore.hasRoles(except)) {
                return { name: 'home' }
            }
        }
    }
});

router.afterEach((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    const menuStore = useMenuStore()
    if(to.meta && to.meta.parent) {
        const toMetaParent = router.resolve({ name: to.meta.parent } as RouteLocationRaw)
        const breadcrumbs = [
            {
                label: from && from.name ? from.meta.label : ( toMetaParent.meta.label ?? ''),
                name: from && from.name ? from.name : ( toMetaParent.name ?? ''),
            },
            {
                label: to.meta.label,
                name: to.name
            }
        ] as Breadcrumb[]

        // if(toMetaParent.meta && toMetaParent.meta.parent) {
        //     const toMetaAncestor = router.resolve({ name: toMetaParent.meta.parent } as RouteLocationRaw)
        //     breadcrumbs.unshift({
        //         label: toMetaAncestor && toMetaAncestor.name ? toMetaAncestor.meta.label : ( toMetaAncestor.meta.label ?? ''),
        //         name: toMetaAncestor && toMetaAncestor.name ? toMetaAncestor.name : ( toMetaAncestor.name ?? ''),
        //         params: from.params,
        //     } as Breadcrumb)
        // }

        menuStore.breadcrumbs = breadcrumbs
    } else {
        const breadcrumbs = [
            {
                label: to.meta.label ?? '',
                name: to.name
            }
        ] as Breadcrumb[]
        menuStore.breadcrumbs = breadcrumbs
    }
});

export default router;
